import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyAn3xjHCWPEPiD3YFPutwRoQ4z4a1a1nDM",
    authDomain: "matka365-bebfb.firebaseapp.com",
    projectId: "matka365-bebfb",
    storageBucket: "matka365-bebfb.appspot.com",
    messagingSenderId: "740244094566",
    appId: "1:740244094566:web:2b32493cdddaa4d1e137a7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)